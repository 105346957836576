import { createLogger } from '@wxu/logger';

const logger = createLogger('script-loader');

const LOADED = 'loaded';
const COMPLETE = 'complete';

export const LOAD_FAILURE = 'failure';
export const LOAD_SUCCESS = 'success';

const scriptLoaded = (element) => {
  if (element.readyState) {
    return (element.readyState === LOADED || element.readyState === COMPLETE);
  }

  return true;
};

export const loadScript = (src, attributes = {}, element = 'head') => new Promise((resolve, reject) => {
  const _element = document.querySelector(element);

  if (!_element) reject(new Error(`${element} not found in document`));

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.src = src;

  Object.keys(attributes).forEach((attr) => {
    script[attr] = attributes[attr];
  });

  script.onerror = () => {
    if (attributes.onerror) {
      logger.debug(attributes.onerror, `Calling onerror attribute for script ${src}`);
      attributes.onerror();
    }

    logger.error(`Error occurred loading script ${src}`);
    resolve(LOAD_FAILURE);
  };

  script.onload = () => {
    if (scriptLoaded(script)) {
      if (attributes.onload) {
        logger.debug(attributes.onload, `Calling onload attribute for script ${src}`);
        attributes.onload();
      }

      logger.debug(`Successfully loaded script ${src}`);
      resolve(LOAD_SUCCESS);
    }
  };

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === LOADED || script.readyState === COMPLETE) {
        if (attributes.onreadystatechange) {
          logger.debug(attributes.onreadystatechange, `Calling onreadystatechange attribute for script ${src}`);
          attributes.onreadystatechange();
        }

        resolve(LOAD_SUCCESS);
      }
    };
  }

  if (document.getElementById(attributes?.id)) {
    logger.debug(`Avoiding recalling script with the same id attribute ${attributes.id} for script ${src}`);
    resolve(LOAD_FAILURE);
  } else {
    logger.debug(`Loading script ${src}`);
    _element.appendChild(script);
  }
});

export const loadScriptJsonp = (jsonpSrc, async) => new Promise((resolve) => {
  const callbackName = `jsonp_callback_${Math.round(100000 * Math.random())}`;
  const script = document.createElement('script');
  const src = jsonpSrc.replace(/=\?/, `=${callbackName}`);

  window[callbackName] = (data) => {
    window[callbackName] = null;
    logger.debug(`Successful callback for ${src}`);
    resolve(data);
  };

  script.async = async;
  script.src = src;
  script.onerror = () => {
    logger.error(`Error occurred loading script ${src}`);
    resolve(LOAD_FAILURE);
  };

  logger.debug(`Loading script ${src}`);

  document.getElementsByTagName('head')[0].appendChild(script);
});
